import React, { useEffect, useState } from "react";
import { Testimonials } from "./testimonials";
import { graphql, useStaticQuery } from "gatsby";

export const BusinessTestimonials = () => {
  const [testimonialData, setTestimonialData] = useState([]);
  const data = useStaticQuery(graphql`
    query BusinessestimonialsQuery {
      allGoogleSheet {
        edges {
          node {
            businesstestimonials {
              testimonialquote
              testimonialauthor
              testimonialadditional
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    const edge = data?.allGoogleSheet?.edges?.[0];
    const node = edge?.node;
    const testimonials = node?.businesstestimonials;
    console.log(testimonials);
    setTestimonialData(testimonials);
  }, [data]);

  if (!data) return null;

  return <Testimonials testionals={testimonialData} />;
};
